<template>
  <div class="academic-years">
    <lenon-list-page
      title="Attendance List (Current Term)"
      :columns="columns"
      :rows="attendanceList"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      :show-profile-photo="true"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <!--          <lenon-select-->
          <!--            v-model="selectedAcademicYearId"-->
          <!--            placeholder="Academic Year"-->
          <!--            :options="academicYears"-->
          <!--            label-name="name"-->
          <!--            value-name="id"-->
          <!--            class="mr-1"-->
          <!--            @input="fetchAttendances"-->
          <!--          />-->
          <!--          <lenon-select-->
          <!--            v-model="selectedTermId"-->
          <!--            placeholder="Select Term"-->
          <!--            :options="terms"-->
          <!--            label-name="name"-->
          <!--            value-name="id"-->
          <!--            class="mr-1"-->
          <!--            @input="fetchAttendances"-->
          <!--          />-->
          <lenon-select
            v-model="selectedClassId"
            :options="classes"
            label-name="name"
            value-name="id"
            placeholder="Select Class"
            @input="fetchAttendances"
          />
          <!--          <lenon-date-picker-->
          <!--            v-model="selectedDate"-->
          <!--            class="mr-2"-->
          <!--            label="Date"-->
          <!--            :show-label="false"-->
          <!--            @input="fetchAttendances"-->
          <!--          />-->
        </div>
      </template>
      <template
        slot="table-header"
      >
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-md-6"
        >
          <lenon-select
            v-model="selectedClassId"
            :options="classes"
            label-name="name"
            value-name="id"
            placeholder="Select Class"
            @input="fetchAttendances"
          />
        </div>
        <div
          v-if="attendanceList.length>0"
          class="row px-1 pb-1"
        >
          <div class="col-12">
            Attendance has been recorded <strong>{{ attendanceList[0].total_school_days }}</strong> times
          </div>
        </div>
      </template>
      <template #full_name="{row}">
        {{ row.item.first_name }} {{ row.item.middle_name }} {{ row.item.last_name }}
      </template>
      <template #attendance_status="{row}">
        <b-badge
          v-if="row.item.attendance_status===0"
          :icon-only="true"
          variant="light-danger"
        >
          Absent
        </b-badge>
        <b-badge
          v-if="row.item.attendance_status===1"
          :icon-only="true"
          variant="light-success"
        >
          Present
        </b-badge>
        <b-badge
          v-if="row.item.attendance_status===2"
          :icon-only="true"
          variant="light-primary"
        >
          Absent with permission
        </b-badge>
        <b-badge
          v-if="row.item.attendance_status===null"
          :icon-only="true"
          variant="light-danger"
        >
          Not recorded
        </b-badge>
      </template>
      <!--      <template #photo="{row}">-->
      <!--        <b-avatar-->
      <!--          variant="light-primary"-->
      <!--          size="40"-->
      <!--          :src="getFullPath(row.item.photo)"-->
      <!--        />-->
      <!--      </template>-->
    </lenon-list-page>
  </div>
</template>

<script>
import {
  BAvatar, BBadge,
} from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { FETCH_ATTENDANCE_LIST_Q } from '@/graphql/queries'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'

export default {
  name: 'AttendanceList',
  components: {
    LenonDatePicker,
    LenonSelect,
    LenonListPage,
    BAvatar,
    BBadge,
  },
  mixins: [showToast],
  data() {
    return {
      studentsModalOpened: false,
      tableLoading: false,
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      selectedDate: null,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Full Name',
        },
        {
          key: 'attendance_status',
          label: 'Attendance Status For Today',
        },
        {
          key: 'present',
          label: 'Total Presents',
        },
        {
          key: 'absent',
          label: 'Total Absents',
        },
        {
          key: 'absent_with_permission',
          label: 'Total Absents With Permission',
        },
        {
          key: 'absence_rate',
          label: 'Absence Rate(%)',
        },
      ],
    }
  },
  computed: {
    attendanceList() {
      return this.$store.getters['students/attendanceList']
    },
    previousSetting() {
      return this.$store.getters['students/previousAttendanceListSetting']
    },
    classes() {
      return this.$store.getters['termsClasses/termClasses']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
  },
  mounted() {
    this.setSettings()
    this.fetchAttendances()
  },
  methods: {
    setSettings() {
      this.selectedClassId = this.previousSetting.selectedClassId
      this.selectedAcademicYearId = this.previousSetting.selectedAcademicYearId
      this.selectedTermId = this.previousSetting.selectedTermId
      this.selectedDate = this.previousSetting.selectedDate
    },
    getFullPath(photo) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${photo}`
    },
    // eslint-disable-next-line camelcase
    fetchAttendances() {
      if (!this.selectedClassId) {
        this.$store.commit('students/setAttendanceList', [])
        return
      }
      this.$store.commit('students/setAttendanceListSetting', {
        selectedClassId: this.selectedClassId,
        selectedAcademicYearId: this.selectedAcademicYearId,
        selectedTermId: this.selectedTermId,
        selectedDate: this.selectedDate,
      })
      this.tableLoading = true
      // use 0 ay and 0 term so api uses current ay and term
      this.$apollo.query({
        query: FETCH_ATTENDANCE_LIST_Q,
        variables: {
          input: {
            date: this.selectedDate,
            class_id:
        this.selectedClassId,
            academic_year_id: 0,
            term_id: 0,
          },
        },
      })
        .then(res => {
          this.tableLoading = false
          if (!res.errors) {
            this.$store.commit('students/setAttendanceList', res.data.attendanceList)
          }
        }).catch(err => {
          this.tableLoading = false
          this.showError('Failed to load trips list')
        })
    },

  },
}
</script>
